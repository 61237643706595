<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div class="mb-1">
          <h2 class="mb-1">
Success
</h2>
          <!-- <b-img class="logo-img" fluid :src="checkedImg" alt="failure page" /> -->
        </div>
        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          Back to home
        </b-button>
        <b-img fluid :src="imgUrl" alt="Failure page" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BButton,
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line import/no-unresolved
    //   checkedImg: require("@assets/images/pages/checked.png"),
      downImg: require("@/assets/images/pages/error-dark.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.logo-img {
  width: 100%;
  max-width: 50px;
}
</style>
